html
{
  background-color: #10312F;
}
body,
input,
button {
  font-family: "Capriola", sans-serif; }

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }

a {
  text-decoration: none; }

.btn-login, .btn-switch {
  padding: 1em 3em;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  outline: none;
  border: 2px solid #29b1cc; }
  .btn-login:hover, .btn-switch:hover {
    cursor: pointer; }

.btn-login, .btn-switch:hover {
  background-color: #29b1cc;
  color: #fff; }

.btn-switch {
  background-color: #fff;
  color: #29b1cc; }

.btn-login:hover {
  box-shadow: 0 10px 20px rgba(41, 177, 204, 0.3); }

.btn-link {
  color: #29b1cc;
  border: none;
  border-bottom: 1px solid #29b1cc;
  padding: 0;
  margin-left: 2em; }
  .btn-link:hover {
    border-bottom: none;
    cursor: pointer; }

.login {
  width: 35vw; }
  .login h1 {
    text-align: center;
    margin-bottom: 1em; }
  .login h2 {
    text-align: center;
    color: #29b1cc; }
  .login .auth-message {
    text-align: center;
    color: orange; }
  .login .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em; }
    .login .input-group label {
      margin-bottom: 0.8em; }
    .login .input-group input {
      outline: none;
      border: 1px solid #dfe2e6;
      color: #6b6c6f;
      border-radius: 20px;
      padding: 1.2em 1.5em; }
      .login .input-group input.input-error {
        border: 1px solid red; }
    .login .input-group small {
      font-size: 0.6em;
      margin: 0.6em 0 0 1.7em;
      color: red; }
  .login .login-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em; }

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .page h1 {
    margin-bottom: 0; }
  .page .emoji {
    font-size: 4em; }
  .page button,
  .page a {
    margin-top: 3em; }

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2); }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1); }
  100% {
    top: 0; } }
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loader .loader-bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px; }
    .loader .loader-bounceball:before {
      position: absolute;
      top: 0;
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #29b1cc;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease; }
  .loader .loader-text {
    color: #29b1cc;
    display: inline-block;
    margin-left: 1em; }

.sk-circle {
  margin: 0 auto;
  width: 1.5em;
  height: 1.5em;
  position: relative; }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
